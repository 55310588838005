import React, {useState, useEffect} from 'react'
import './Searchbar.css'
import Searchicon from '../Assets/Searchicon.png';
import axios from 'axios';

const Searchbar = () => {
 
    const [query, setQuery] = useState('');
    const [display_data, setDisplay_data] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          const response = await fetch(`http://waittimesontario.com/display_data.json?_${new Date().getTime()}`);
          const jsonData = await response.json();
          setDisplay_data(jsonData);
        };
    
        fetchData();
      }, [display_data]);

    const handleSearch = async (e) => {
        const searchQuery = e.target.value;
        setQuery(searchQuery);

        if (display_data.find(entry => entry.care_centre === 'Kingston MRI' && entry.estimated_wait_time !== 'Not performed here')) {
            axios.post('http://waittimesontario.com/Search_mri.php',{search1: searchQuery})
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.error(error.response);
            });
        }

        else {
            axios.post('http://waittimesontario.com/Search_ct.php',{search2: searchQuery})
            .then(response => {
                console.log(response.data);
              })
            .catch(error => {
                console.error(error.response);
              });
        }
    }

    return (
        <div>
            <input type='text' className='s1' placeholder={'Search care centres'} value={query} onChange={handleSearch} />
            <img className='s2' src={Searchicon} alt='Search icon'/>
        </div>
    )
  }
  
  export default Searchbar;