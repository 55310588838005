import React, {useState, useEffect, useRef} from 'react'
import './Sortbar.css'
import Sorticon from '../Assets/Sorticon.png';
import axios from 'axios';
import Sortindicator from './Sortindicator';

function Sortbar() {
   
    const [open, setOpen] = useState(false);
    const [sortMethod, setSortMethod] = useState("MRI Wait Time");

    let menuRef = useRef();

    useEffect(() => {
        let handler = (e)=>{
            if(!menuRef.current.contains(e.target)){
                setOpen(false);
                console.log(menuRef.current);
            }
        };

        document.addEventListener('mousedown', handler);
    });

    function Dropdownitem(props) {
        return(
            <li className='dropdown-item' onClick={props.onClick}>
                {props.text}
            </li>
        );
    }
    
    const MRIWaitTimeClick = () => {
        axios.get('http://waittimesontario.com/Organize_mri_wt.php')
        .then(response => {
            console.log(response.data);
            setSortMethod('MRI Wait Time');
            console.log(sortMethod);
          })
        .catch(error => {
            console.error(error.response);
          });
      };
    
    const CTWaitTimeClick = () => {
        axios.get('http://waittimesontario.com/Organize_ct_wt.php')
        .then(response => {
            console.log(response.data);
            setSortMethod('CT Wait Time');
            console.log(sortMethod);
          })
        .catch(error => {
            console.error(error.response);
          });
      };  
    
    const MRIDistanceClick = async () => {
    
        const location = prompt('Please enter your location:');
        console.log('User location:', location);
    
        const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=AIzaSyAqWDbSczyBndyPK13hfbVqbC4_6TP9DMQ`
          );
        console.log('Geocoding response:', response.data);
        const { lat, lng } = response.data.results[0].geometry.location;
        
        axios.post('http://waittimesontario.com/Organize_mri_d.php',{lat: lat, lng: lng})
        .then(response => {
            console.log(response.data);
            setSortMethod('MRI Distance');
            console.log(sortMethod);
          })
        .catch(error => {
            console.error(error.response);
          });
    
      };
    
    const CTDistanceClick = async () => {
        const location = prompt('Please enter your location:');
        console.log('User location:', location);

        const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=AIzaSyAqWDbSczyBndyPK13hfbVqbC4_6TP9DMQ`
          );
        console.log('Geocoding response:', response.data);
        const { lat, lng } = response.data.results[0].geometry.location;
    
        axios.post('http://waittimesontario.com/Organize_ct_d.php',{lat: lat, lng: lng})
        .then(response => {
            console.log(response.data);
            setSortMethod('CT Distance');
            console.log(sortMethod);
          })
        .catch(error => {
            console.error(error.response);
          });
      };    

    return (
        <div className='menu' onClick={()=>{setOpen(!open)}} ref={menuRef}>
            <img src={Sorticon} className='sor1' alt='Sort icon'></img>
            <div className='sor2'>Sort by</div>
            <div className={`menu ${open? 'active' : 'inactive'}`}></div>
            <ul className={`dropdown-menu ${open? 'active' : 'inactive'}`}>
                <Dropdownitem text='MRI Wait Time' onClick={MRIWaitTimeClick}></Dropdownitem>
                <Dropdownitem text='MRI Distance' onClick={MRIDistanceClick}></Dropdownitem>
                <Dropdownitem text='CT Wait Time' onClick={CTWaitTimeClick}></Dropdownitem>
                <Dropdownitem text='CT Distance' onClick={CTDistanceClick}></Dropdownitem>
            </ul>
            <Sortindicator sortMethod={sortMethod} />
        </div>
    );
}

export default Sortbar;

