import './Sortindicator.css';

const Sortindicator = (props) => {
    return (
        <div>
            <div className='indicator'>
                <div className='indicator_text'>{props.sortMethod}</div>
            </div>
        </div>
    )
  }
  
  export default Sortindicator;