import React from 'react'
import './Homepage.css'
import { Table } from '../Components/Table.js';

function Homepage() {
    return (
        <body>
            <Table />
        </body>
    )
}

export default Homepage