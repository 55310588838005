import React from 'react'
import './Aboutpage.css'

function Aboutpage() {
    return (
        <body>
            <div className='box1'>
                <div className='box1_text1'>What is Wait Times Ontario?</div>
                <div className='box1_text2'>
                Wait Times Ontario is a tool primarily for patients 
                and physicians to help improve the process of making a diagnostic imaging 
                appointment through education in Ontario, Canada. 
                <p></p>
                It is also intended to bring greater transparency
                and insight to patients and physicaians on the medical imaging booking process 
                that way they can make best use of the resources availble to them.
                </div>
            </div>
            <div className='box2'>
                <div className='box2_text1'>How you can use Wait Times Ontario.</div>
                <div className='box2_text2'>
                Wait Times Ontario aims to provide quick and easy visualization of the 
                most important info on medical imaging centres in Ontario, Canada. 
                <p></p>
                Users can also organize imaging centres by estimated wait time, distance from their home and type of imaging (MRI, CT, etc). Users can make 
                imaging requests at centres that best align with their personal needs, whether that be the centre with 
                the quickest wait time, the centre closest to them, or any other pontential reason. 
                <p></p>
                Through making this info more readily available to patients and physicians we hope to help improve the process for making a 
                medical imaging appointment.
                </div>
            </div>
            <div className='box3'>
                <div className='box3_text1'>How to decrease your wait time!</div>
                <div className='box3_text2'>
                1. Ensuring the shortest wait time for your imaging appointment starts with 
                the inital request for imaging. Make sure the request includes as much information as 
                possible to ensure the most appropriate type of imaging and priority is assigned to your request.
                <p></p>
                2. Use the available information on wait times to select an imaging centre with the shortest wait near you. Ideally this 
                could be checked by calling the imaging centre.
                <p></p>
                3. If you're flexible with when you can come for an appointment, call the imaging centre to 
                ask about a cancellation list or any cancellations on a REGULAR basis.
                <p></p>
                4. Double check your appointment date and time and set reminders to ensure you make you're appointment.
                <p></p>
                5. The above info is meant to provide logistical guidance and not medical advice. Please speak with your physician regarding all health decisions.
                </div>
            </div>
            <div className='box4'>
                <div className='box4_text1'>Things to keep in mind...</div>
                <div className='box4_text2'>
                Please keep in mind that while we aim to provide accurate and up to date information, 
                theres is likely to be errors given the scattered and constantly changing nature of this information. In the absence 
                of real time reporting of medical imaging wait times and centralized reporting of these imaging centre details, Wait Times Ontario 
                hopes to help fill in some of these details with the lmited avaiable information. 
                <p></p>
                Estimated wait times are calculated based on a algorithm combining pulicly reported historic wait times for outpatient imaging and user reviews of their own outpatient wait times. However please keep 
                in mind that this is only an estimate and its accuracy cannot be guarantted. Individual wait times can be impacted by the priority of the situation. The best way to check the up to date wait time for a medical imaging care centre is to 
                call them. 
                <p></p>
                None of the information provided is medical advice, please speak with your physician regarding all healthcare decisions.
                </div>
            </div>
        </body>
    )
}

export default Aboutpage