import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className='footer'>
            <div className='f1'>© 2023 WaitTimesOntario</div>
            <div className='f2'>WaitTimesOntario@gmail.com</div>
            <Link to='/'>
                <div className='f3'>See Wait Times</div>
            </Link>
            <Link to='about'>
                <div className='f4'>What is Wait Times Ontario?</div>
            </Link>
        </div>
    )
}

export default Footer