import React, { useState, useMemo, useEffect } from 'react';
import { useTable } from 'react-table';
import { COLUMNS } from './Columns.js';
import './Table.css';

export const Table = () => {

const columns = useMemo(() => COLUMNS, []);
const [data, setData] = useState([]);

const tableInstance = useTable({
    columns: columns,
    data: data
})

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance

    useEffect(() => {
        const fetchData = async () => {
          const response = await fetch(`http://waittimesontario.com/display_data.json?_${new Date().getTime()}`);
          const jsonData = await response.json();
          setData(jsonData);
        };
    
        fetchData();
      }, [data]);
    
    return(
        <table {...getTableProps()}>
        <thead>
            {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
            </tr>
            ))}
        </thead>
        <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
            prepareRow(row)
            return (
            <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
            </tr>
            )})}
        </tbody>
        </table>
    )
}