import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Reviewbutton.css';

const Reviewbutton = () => {

  const [cc, setCc] = useState('');
  const [wt, setWt] = useState('');
  const [im, setIm] = useState('');
  const [showForm, setShowForm] = useState(false);

  const handleChange1 = (event) => {
    setCc(event.target.value);
  };

  const handleChange2 = (event) => {
    setIm(event.target.value);
  };

  const handleChange3 = (event) => {
    setWt(event.target.value);
  };

  const handleAddReviewClick = () => {
    setShowForm(true);
  };

  const formRef = useRef(null); 

  const handleClickOutsideForm = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setShowForm(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    axios
      .post('http://waittimesontario.com/Add_review.php', { cc: cc, wt: wt, im: im })
      .then((response) => {
        console.log(response.data);
        setShowForm(false);
      })
      .catch((error) => {
        console.error(error.response);
      });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideForm);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideForm);
    };
  }, []);

  return (
    <div>
      {showForm && (
        <form ref={formRef} onSubmit={handleSubmit}>
            <div className="reviewform">
            <p className='reviewformh'>Tell us your wait time to help keep our estimated wait times up to date!</p>
            <p><label htmlFor="reviewInput">Care Centre:</label>
            <input type="text" id="cc" value={cc} onChange={handleChange1} /></p>
            <p><label htmlFor="reviewInput">Imaging type (CT or MRI):</label>
            <input type="text" id="im" value={im} onChange={handleChange2} /></p>
            <p><label htmlFor="reviewInput">Wait Time (Days):</label>
            <input type="text" id="wt" value={wt} onChange={handleChange3} /></p>
            <button type="submit" className="reviewbutton" onClick={handleSubmit}>+ Add review</button>
            </div>
          <button type="submit" className="submitreviewbutton">Submit review</button>
        </form>
      )}
      {!showForm && (
        <button className="reviewbutton" onClick={handleAddReviewClick}>
          + Add review
        </button>
      )}
    </div>
  );
};

export default Reviewbutton;

