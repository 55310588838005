export const COLUMNS = [
    {
        Header: 'Care Centre',
        accessor: 'care_centre'
    },
    {
        Header: 'Estimated Wait Time (days)',
        accessor: 'estimated_wait_time'
    },
    {
        Header: 'Distance (km)',
        accessor: 'distance'
    },
    {
        Header: 'Address',
        accessor: 'address_1'
    },
    {
        Header: 'Contact Info',
        accessor: 'contact_info'
    },
    {
        Header: 'Requsitions',
        accessor: 'requisition'
    },
    {
        Header: 'Wait List',
        accessor: 'wait_list'
    }
]

