import React from 'react'
import './Header.css'
import Logo from '../Assets/Logo.png';
import Searchbar from './Searchbar.js';
import { Link } from 'react-router-dom';
import Sortbar from './Sortbar.js';
import Reviewbutton from './Reviewbutton.js';
import Sortindicator from './Sortindicator.js';

function Header() {
    return (
        <div className='header'>
            <Link to='/'>
                <img className='h1' src={Logo} alt='Logo'/>
            </Link>
            <div className='h2'>Wait Times Ontario</div>
            <div className='h3'>Diagnostic Imaging</div>
            <Searchbar />
            <Sortbar />
            <Reviewbutton />
            <Sortindicator />
        </div>
    );
}

export default Header